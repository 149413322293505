<template lang="pug">
  v-container.custom-container
    v-snackbar(v-model='snackbar.show' :color='snackbar.color' bottom center)
      | {{snackbar.message}}
      v-btn(dark='' text='' @click='snackbar.show = false')
        | Close
    v-layout(column align-center)
      v-layout(column xs12='' align-center).pt-2.ma-2.mt-3.text-center
        .title(style="color: rgb(76,76,76); font-size: 2.2rem !important; letter-spacing: -1.5px !important;") {{title}}
        v-divider.primary.mt-5( style="width: 100px;")
    v-layout(column style="font-size: 15px;" ).text-xs-justify.pa-3
      p.text-center Mailing address: P.O. Box 6993, Seffner, FL 3358.
      v-layout(column).pa-2
        v-text-field(v-model="model.email" rounded solo :rules="emailRules" prepend-inner-icon="mdi-email" label="Email" round required="")
      v-layout(column).ml-3
        p.text-center Select the subject
        v-select(v-model="model.subject" :items="subjects" dense rounded solo label="Choose your subject reason" single-line).mt-1
        v-textarea(v-model="model.text" dense solo rounded rows="10")
    v-layout(column)
      v-flex.d-flex.align-center.justify-center
        v-btn.text-uppercase.mx-auto.video-card__buy(color="primary" small @click="sendContact" :disabled="!model.email || !model.subject || !model.text" :loading="loading") Send
</template>

<script>
  export default {
    data () {
      return {
        title: 'Contact us',
        model: {
          email: '',
          subject: '',
          text: '',
          date: '',
        },
        snackbar: {
          show: false,
          message: '',
          color: '',
        },
        emailRules: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        subjects: ['Feedback', 'Complaints', 'General inquiries', 'Custom Plan'],
        questions: {
          q1: '',
          q2: '',
          q3: '',
          q4: '',
          q5: '',
          q6: '',
        },
        select_1: ['Ninguna', '1 a 5', '5 a 10', ' mas de 10'],
      }
    },
    computed: {
      user () {
        return this.$store.state.users.user
      },
      loading () {
        return this.$store.getters['users/getLoadingContact']
      },
    },
    created () {
      const vm = this
      if (vm.user.email) {
        vm.model.email = vm.user.email
      }
    },
    mounted () {
      const vm = this
      if (vm.$route.params.subjects === 'plan') {
        vm.model.subject = 'Custom Plan'
      }
    },
    methods: {
      refresh () {
        const vm = this
        vm.model.subject = ''
        vm.model.text = ''
      },
      sendContact () {
        const vm = this
        // console.log(vm.model)
        vm.model.date = new Date().getTime()
        vm.$store.dispatch('users/sendContact', vm.model).then(res => {
          vm.snackbar = {
            show: true,
            message: 'Contact message sent',
            color: 'success',
          }
          vm.refresh()
        }).catch(error => {
          console.log(error)
          vm.snackbar = {
            show: true,
            message: 'Please try again',
            color: 'warning',
          }
        })
      },
    },
  }
</script>

<style lang='scss' scoped>

</style>
